<p-dialog
  class="content-space-0 m-3 p-3"
  [showHeader]="false"
  [(visible)]="dialogVisible"
  [modal]="true"
  position="center"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100%', maxWidth: '700px' }"
  (onHide)="closeThisDialog()"
>
  <!-- Warning Icon and Title -->
  <div class="flex flex-column align-items-center mt-4">
    <div class="flex flex-column align-items-center position-relative">
      <div class="flex justify-content-between w-100">
        <!-- Warning Icon -->
        <div class="icon-box icon-box-lg">
          <i class="pi pi-exclamation-triangle warning-icon text-warning"></i>
        </div>

        <!-- Close Button -->
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-times"
          class="close-btn"
          (click)="closeThisDialog()"
        ></button>
      </div>
    </div>

    <h4 class="font-medium text-xl mt-2">User have future renewal plan.</h4>
  </div>

  <!-- Message -->
  <div class="text-center font-normal mt-4">
    <p>
      {{ subHeading }}
    </p>
    <p>{{ arabicHeading }}</p>
  </div>

  <!-- Actions -->

  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-end pt-2 mt-2">
      <p-button
        label="Cancel"
        [text]="true"
        (click)="closeThisDialog()"
      ></p-button>
      <p-button
        label="Proceed"
        class="p-button-raised p-button-primary rw__ms-5"
        (click)="handleSubmit()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
