import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckOutFlows } from '@rewaa-team/types';

@Component({
  selector: 'rwa-refund-renewal-plan-warning',
  templateUrl: './refund-renewal-plan-warning.component.html',
  styleUrls: ['./refund-renewal-plan-warning.component.scss'],
})
export class RefundRenewalPlanWarningComponent implements OnInit {
  @Input() dialogVisible = false;

  @Input() checkoutFlow: CheckOutFlows = undefined;

  @Output() closeDialog = new EventEmitter<void>();

  @Output() proceed = new EventEmitter<void>();

  subHeading: string = undefined;

  headingThree: string = undefined;

  arabicHeadingThree: string = undefined;

  submitButtonLabel: string = undefined;

  ngOnInit(): void {
    this.setSubHeading();
    this.setThirdHeading();
    this.setSubmitButtonLabel();
  }

  closeThisDialog(): void {
    this.closeDialog.emit();
  }

  handleSubmit(): void {
    this.proceed.emit();
  }

  setSubHeading(): void {
    if (
      this.checkoutFlow === CheckOutFlows.UpgradePlan ||
      this.checkoutFlow === CheckOutFlows.RenewPlan
    )
      this.subHeading = 'By confirming upgrading system will do the following';
    else
      this.subHeading =
        'By confirming buying an add-on system will do the following';
  }

  setThirdHeading(): void {
    if (this.checkoutFlow === CheckOutFlows.UpgradePlan) {
      this.headingThree = 'Upgrade current plan';
      this.arabicHeadingThree = 'سيتم ترقية الباقة الحالية';
    } else if (this.checkoutFlow === CheckOutFlows.RenewPlan) {
      this.headingThree = 'Purchase New Renewal plan';
      this.arabicHeadingThree = 'سيتم شراء باقة تجديد أخرى';
    } else {
      this.headingThree = 'Add-on applied to current plan';
      this.arabicHeadingThree = 'سيتم إضافة التطبيق على الباقة الحالية';
    }
  }

  setSubmitButtonLabel(): void {
    if (this.checkoutFlow === CheckOutFlows.UpgradePlan)
      this.submitButtonLabel = 'Confirm Plan Upgrade';
    else if (this.checkoutFlow === CheckOutFlows.RenewPlan)
      this.submitButtonLabel = 'Confirm Renewal Plan';
    else this.submitButtonLabel = 'Confirm Add-on Purchase';
  }
}
