import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CheckOutFlows } from '@rewaa-team/types';

@Component({
  selector: 'rwa-future-renewal-warning',
  templateUrl: './future-renewal-warning.component.html',
  styleUrls: ['./future-renewal-warning.component.scss'],
})
export class FutureRenewalWarningComponent implements OnChanges {
  @Input() dialogVisible = false;

  @Input() checkoutFlow: CheckOutFlows = undefined;

  @Output() closeDialog = new EventEmitter<void>();

  @Output() proceed = new EventEmitter<void>();

  subHeading: string = undefined;

  arabicHeading: string = undefined;

  ngOnChanges(): void {
    this.setSubHeading();
    this.setArabicHeading();
  }

  closeThisDialog(): void {
    this.closeDialog.emit();
  }

  handleSubmit(): void {
    this.proceed.emit();
  }

  setSubHeading(): void {
    if (this.checkoutFlow === CheckOutFlows.UpgradePlan)
      this.subHeading =
        'By upgrading plan system will refund the active renewal plan.';
    else if (this.checkoutFlow === CheckOutFlows.RenewPlan)
      this.subHeading =
        'Purchasing a new renewal plan will automatically refund the active one.';
    else
      this.subHeading =
        'By purchasing an add-on, the system will refund the active renewal plan.';
  }

  setArabicHeading(): void {
    if (this.checkoutFlow === CheckOutFlows.UpgradePlan)
      this.arabicHeading =
        'عند ترقية باقتك سيتم إرجاع باقة التجديد التي تم شرائها سابقًا';
    else if (this.checkoutFlow === CheckOutFlows.RenewPlan)
      this.arabicHeading =
        'عند شراء باقة تجديد أخرى سيتم إرجاع باقة التجديد التي تم شرائها سابقًا';
    else
      this.arabicHeading =
        'عند إضافة تطبيق / فرع سيتم إرجاع باقة التجديد التي تم شرائها سابقًا';
  }
}
