<p-dialog
  class="content-space-0 m-3 p-3 custom-dialog"
  [showHeader]="false"
  [(visible)]="dialogVisible"
  [modal]="true"
  position="center"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100%', maxWidth: '900px' }"
  (onHide)="closeThisDialog()"
>
  <!-- Warning Icon and Title -->
  <div class="pl-4 pr-4">
    <div class="flex align-items-center justify-content-between w-100 pt-3">
      <!-- Warning Icon and Title -->
      <div class="icon-box">
        <i class="pi pi-exclamation-triangle warning-icon"></i>
        <h4 class="font-medium text-xl">Refund renewal plan</h4>
      </div>

      <!-- Close Button -->
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="close-btn"
        (click)="closeThisDialog()"
      ></button>
    </div>

    <div class="font-normal mt-4">
      <p class="text-normal font-medium">
        {{ subHeading }}
      </p>
    </div>

    <!-- Three Boxes Section -->
    <div class="flex justify-content-between mt-4">
      <!-- Box 1 -->
      <div class="box">
        <div>
          <i class="fa-regular fa-calendar icon-center"></i>
        </div>
        <p class="text-center"><strong>Refund renewal invoice</strong></p>
        <p class="text-center">سيتم إصدار فاتورة إرجاع لباقة التجديد</p>
      </div>

      <!-- Box 2 -->
      <div class="box">
        <div>
          <i class="fa-regular fa-ban icon-center"></i>
        </div>
        <p class="text-center"><strong>Remaining amount credited</strong></p>
        <p class="text-center">سيتم إضافة المبلغ المتبقي كرصيد للمستخدم</p>
      </div>

      <!-- Box 3 -->
      <div class="box">
        <div>
          <i class="fa-regular fa-layer-group icon-center"></i>
        </div>

        <p class="text-center">
          <strong>{{ headingThree }}</strong>
        </p>
        <p class="text-center">{{ arabicHeadingThree }}</p>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-end pt-2 mt-2">
      <p-button
        label="Back"
        [text]="true"
        (click)="closeThisDialog()"
      ></p-button>
      <p-button
        [label]="submitButtonLabel"
        class="p-button-raised p-button-primary rw__ms-5"
        (click)="handleSubmit()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
